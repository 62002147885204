import request from '@/utils/request'

// 获取案例标签列表
export function signGetList(params) {
  return request({
    url: '/zqlabel/getList',
    method: 'get',
    params
  })
}

// 获取案例列表
export function caseGetList(params) {
  return request({
    url: '/ZqContent/getList',
    method: 'get',
    params
  })
}

