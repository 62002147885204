<template>
  <BannerStyle1 :list="bannerList" :position="13"></BannerStyle1>
  <el-affix position="top" :offset="0">
    <section class="sort-menu flex-between usually-box">
      <div v-for="(item, index) in sortList.list" :key="index" :class="{'on': form.lId === item.id}" @click="changeMenu(item)">
        <div class="img-box flex-center">
          <img class="sort-img normal-img" :src="item.logo" alt="">
          <img class="sort-img active-img" :src="item.logoIcon" alt="">
        </div>
        <p class="sort-text">{{item.labelName}}</p>
      </div>
    </section>
  </el-affix>
  <section class="case-box usually-box">
    <ul v-if="caseList.list.length">
      <li v-for="(item, index) in caseList.list" :key="index">
        <img :src="item.thumbnail" alt="">
        <div class="case-info">
          <h3>{{item.title}}</h3>
          <p class="over-text3">{{item.describe}}</p>
          <div class="sign-box">
            <el-tag style="margin-right: 10px" v-for="(tag, tagIndex) in item.zqLabelList" :key="tagIndex">{{tag && tag.labelName}}</el-tag>
          </div>
          <div class="btn-box">
            <el-button type="primary" @click="toPage(item, '/case/details')">查看详情
              <el-icon style="margin-left: 5px" :size="16">
                <ArrowRight />
              </el-icon>
            </el-button>
          </div>
        </div>
      </li>
    </ul>
    <div class="empty-box">
      没有数据
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, computed, onBeforeMount } from 'vue'
import { ArrowRight } from '@element-plus/icons'
import { useRouter } from 'vue-router'
import { signGetList, caseGetList } from '@/api/case'
const route = reactive(useRouter())
function toPage(item, url) {
  sessionStorage.setItem('case', JSON.stringify(item))
  route.push(url)
}

const form = reactive({
  lId: undefined

})

const nowMenu = ref(0)

const bannerList = [
  {
    img: require("@/assets/case/banner.png"),
    title: '中国泛家居绿色智慧供应链平台',
    desc: '可信区块链 + 大数据 + 隐私计算',
    btnText: '了解案例',
  }
]

const sortList = reactive({
  list: [
    {
      id: undefined,
      logo: require('@/assets/case/case-menu1.png'),
      logoIcon: require('@/assets/case/case-menu2.png'),
      labelName: '全部',
    },
  ]
})


const caseList = reactive({
  list: []
})


function getSign() {
  signGetList({
    page: 1,
    size: 20
  }).then(res => {
    sortList.list = sortList.list.concat(res.data.data)
  })
}

function getCaseList() {
  caseGetList({
    lId: form.lId
  }).then(res => {
    caseList.list = res.data.data
  })
}
onBeforeMount(() => {
  getSign()
  getCaseList()
})

function changeMenu(item) {
  form.lId = item.id
  getCaseList()
}
</script>
<style lang='scss' scoped>
$split-line: #ebebeb;
.banner-box {
  .banner-img {
    width: 100%;
  }
}
.sort-menu {
  justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid $split-line;
  font-size: 18px;
  text-align: center;
  color: #808080;
  background-color: #fff;
  > div {
    cursor: pointer;
    &:hover {
      .normal-img {
        display: none;
      }
      .active-img {
        display: block;
      }
      color: #0766e1;
    }
  }
  .img-box {
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
    .active-img {
      display: none;
    }
  }
  .sort-img {
    width: 50%;
  }
  .on {
    color: $hoverColor;
  }
}

.case-box {
  $paddTop: 40px;
  $imgWidth: 450px;
  padding-top: 40px;
  background-color: #fff;
  > ul {
    > li {
      position: relative;
      border-bottom: 1px solid $split-line;
      font-size: 18px;
      padding: 40px 0;
      display: flex;
      > img {
        flex-shrink: 0;
        margin-right: 40px;
        width: $imgWidth;
        height: 250px;
      }
      .case-info {
        > h3 {
          margin-bottom: 15px;
          font-size: 30px;
          font-weight: normal;
          color: #202020;
        }
        > p {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 1.6em;
          color: #808080;
        }
      }
    }
    .btn-box {
      position: absolute;
      left: $imgWidth + 40px;
      bottom: $paddTop + 10px;
    }
  }
}
.empty-box {
  padding: 50px;
  font-size: 24px;
  color: #999;
  text-align: center;
}
</style>